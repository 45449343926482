import { TextDivider } from '@discretize/react-discretize-components';
import { Box } from '@mui/material';
import { graphql } from 'gatsby';
import React from 'react';
import newyearbounty from '../../../components/pages/bounties/data/newyearbounty.json';
import winterbounty from '../../../components/pages/bounties/data/winterbounty.json';
import Header from '../../../components/pages/bounties/Header';
import Leaderboard from '../../../components/pages/bounties/Leaderboard';
import Layout from '../../../components/Layout';
import SeoHeader from '../../../components/SeoHeader';

const layout = {
  SeoProps: {
    title: 'Fractal Bounties Leaderboards',
    description:
      'Play together with your friends and compete for awesome prizes in the bounties event!',
  },
  ContainerProps: {
    paper: true,
  },
};

function LeaderboardsPage({
  data: {
    wintercmPreview,
    wintert4Preview,
    newyearcmPreview,
    newyeart4Preview,
  },
  location,
}) {
  const getImage = (gatsbyData) => gatsbyData.edges[0]?.node?.gatsbyImageData;

  return (
    <Layout ContainerProps={layout.ContainerProps} location={location}>
      <SeoHeader path={location.pathname} {...layout.SeoProps} />

      <Header page="/bounties/leaderboards" title="Bounties Leaderboards" />

      <TextDivider text="New Year Bounty 2022" />
      <Leaderboard
        data={newyearbounty}
        cm={{ name: 'Siax', picture: getImage(newyearcmPreview) }}
        t4={{ name: 'Swamp', picture: getImage(newyeart4Preview) }}
      />
      <Box mb={5} />
      <TextDivider text="Winter Bounty 2021" />
      <Leaderboard
        data={winterbounty}
        cm={{ name: 'Artsariiv', picture: getImage(wintercmPreview) }}
        t4={{ name: 'Cliffside', picture: getImage(wintert4Preview) }}
      />
    </Layout>
  );
}

export default LeaderboardsPage;
export const query = graphql`
  {
    newyearcmPreview: allImageSharp(
      filter: {
        original: {
          src: { eq: "/static/siax-5ba831fe6ce948791c56237b870858d2.jpg" }
        }
      }
    ) {
      edges {
        node {
          gatsbyImageData(
            width: 80
            height: 80
            transformOptions: { cropFocus: CENTER }
          )
          original {
            src
          }
        }
      }
    }
    newyeart4Preview: allImageSharp(
      filter: {
        original: {
          src: {
            eq: "/static/bloomhunger_spirit_onslaught-bbefa3d49825a6a8690f7a7c9cb05240.jpg"
          }
        }
      }
    ) {
      edges {
        node {
          gatsbyImageData(
            width: 80
            height: 80
            transformOptions: { cropFocus: CENTER }
          )
          original {
            src
          }
        }
      }
    }

    wintercmPreview: allImageSharp(
      filter: {
        original: {
          src: { eq: "/static/artsariiv-b796e131c02a4e72493425df1d9c333e.jpg" }
        }
      }
    ) {
      edges {
        node {
          gatsbyImageData(
            width: 80
            height: 80
            transformOptions: { cropFocus: CENTER }
          )
          original {
            src
          }
        }
      }
    }

    wintert4Preview: allImageSharp(
      filter: {
        original: {
          src: { eq: "/static/header-e424ab8a302f911fd1a155adcc5e15f3.jpg" }
        }
      }
    ) {
      edges {
        node {
          gatsbyImageData(
            width: 80
            height: 80
            transformOptions: { cropFocus: CENTER }
          )
          original {
            src
          }
        }
      }
    }
  }
`;
