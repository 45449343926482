import { Progress } from '@discretize/gw2-ui-new'
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded'
import {
  Avatar,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import EncounterTable from './EncounterTable'
import EncounterBody from './EncouterBody'

const useStyles = makeStyles()((theme) => ({
  icon: {
    fontSize: 18,
  },
  table: {
    padding: theme.spacing(1),
    maxWidth: '100%',
  },
  tabsIcon: { width: 80, height: 80, borderRadius: '50%' },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

function Leaderboard({
  data,
  cm: { picture: cmPicture, name: cmName },
  t4: { picture: t4Picture, name: t4Name },
}) {
  const { classes } = useStyles()

  const [value, setValue] = React.useState(0)
  const [hidden, setHidden] = React.useState(
    new Array(data.length).fill(false, 0),
  )

  const overallRanking = data.sort(
    (a, b) => b['Overall Points'] - a['Overall Points'],
  )

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const tabT4 = (
    <EncounterTable hasLog>
      <EncounterBody
        data={
          data
            ? data.map((entry) => ({
                players: entry.Players,
                videos: entry['T4 Videos'],
                points: entry['T4 Points'],
                professions: entry['T4 Professions'],
                timer: entry['T4 Timer'],
                log: entry['T4 Log'],
                guildName: entry['Guild Name'],
                guildLogo: entry['Guild Logo'],
                guildTag: entry['Guild Tag'],
              }))
            : {}
        }
      />
    </EncounterTable>
  )
  const tabCM = (
    <EncounterTable hasLog>
      <EncounterBody
        data={
          data
            ? data.map((entry) => ({
                players: entry.Players,
                videos: entry['CM Videos'],
                points: entry['CM Points'],
                professions: entry['CM Professions'],
                timer: entry['CM Timer'],
                log: entry['CM Log'],
                guildName: entry['Guild Name'],
                guildLogo: entry['Guild Logo'],
                guildTag: entry['Guild Tag'],
              }))
            : {}
        }
      />
    </EncounterTable>
  )

  const tabTotal = (
    <TableContainer component={Paper} className={classes.table}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left">Team</TableCell>
            <TableCell align="center">Players</TableCell>
            <TableCell align="center">T4 Points</TableCell>
            <TableCell align="center">CM Points</TableCell>
            <TableCell align="center">Total Points</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length === 0 &&
            Array.from(Array(10).keys()).map((index1) => (
              <TableRow key={`${index1}`}>
                {Array.from(Array(6).keys()).map((index2) => (
                  <TableCell align="center" key={`${index1}-${index2}`}>
                    <Progress />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          {overallRanking.map((entry, index) => {
            return hidden[index] ? (
              <TableRow
                key={entry['Guild Name']}
                onClick={() => {
                  const hiddenNew = [...hidden]
                  hiddenNew[index] = false
                  setHidden(hiddenNew)
                }}
              >
                <TableCell>
                  <span style={{ fontSize: '1.8rem' }}>{index + 1}</span>
                </TableCell>
                <TableCell align="left">
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" />
                </TableCell>
              </TableRow>
            ) : (
              <TableRow key={entry['Guild Name']}>
                <TableCell>
                  <span style={{ fontSize: '1.8rem' }}>{index + 1}</span>
                  <Avatar
                    src={entry['Guild Logo']}
                    style={{ float: 'right' }}
                  />
                </TableCell>
                <TableCell align="left">
                  {entry['Guild Name']} [{entry['Guild Tag']}]
                </TableCell>
                <TableCell align="center">
                  {entry.Players.split(',').join(', ')}
                </TableCell>
                <TableCell align="center">{entry['T4 Points']}</TableCell>
                <TableCell align="center">{entry['CM Points']}</TableCell>
                <TableCell align="center">{entry['Overall Points']}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )

  return (
    <>
      <Paper square className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon tabs example"
        >
          <Tab
            icon={<FormatListNumberedRoundedIcon />}
            label="Total"
            aria-label="total"
          />
          <Tab
            icon={
              <GatsbyImage
                image={t4Picture}
                className={classes.tabsIcon}
                alt="T4 Picture"
              />
            }
            label={t4Name}
            aria-label={t4Name}
          />
          <Tab
            icon={
              <GatsbyImage
                image={cmPicture}
                className={classes.tabsIcon}
                alt="CM Picture"
              />
            }
            label={cmName}
            aria-label={cmName}
          />
        </Tabs>
      </Paper>

      <TabPanel value={value} index={0}>
        {tabTotal}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {tabT4}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {tabCM}
      </TabPanel>
    </>
  )
}

export default Leaderboard
