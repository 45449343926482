import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import dayjs from 'dayjs'
import React from 'react'

const duration = require('dayjs/plugin/duration')

dayjs.extend(duration)

const useStyles = makeStyles()((theme) => ({
  iconButton: { padding: '2px !important' },
}))

function EncounterTable({ children, hasLog }) {
  const { classes } = useStyles()

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table aria-label="simple table" padding="normal">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">Team</TableCell>
            <TableCell align="center">Composition</TableCell>
            {hasLog && <TableCell align="center">Log</TableCell>}
            <TableCell align="center">Timer</TableCell>
            <TableCell align="center">Points</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default EncounterTable
