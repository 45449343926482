import { Avatar, IconButton, TableCell, TableRow } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import YouTube from '@mui/icons-material/YouTube';
import dayjs from 'dayjs';
import { DetailsHeader, Tooltip } from '@discretize/gw2-ui-new';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Specialization } from '../../gw2components';
import Link from '../../navigation/Link';
import ModalVideo from '../../ModalVideo';

const duration = require('dayjs/plugin/duration');

dayjs.extend(duration);

const useStyles = makeStyles()((theme) => ({
  iconButton: { padding: '2px !important' },
}));

const EncounterBody = ({ data }) => {
  const { classes } = useStyles();

  const [hidden, setHidden] = React.useState(
    new Array(data.length).fill(false, 0),
  );

  return data
    .sort((a, b) => b.points - a.points)
    .map(
      (
        {
          guildName,
          guildLogo,
          guildTag,
          players,
          professions,
          videos,
          timer,
          log,
          points,
        },
        index,
      ) =>
        hidden[index] ? (
          <TableRow
            key={guildName}
            onClick={() => {
              const hiddenNew = [...hidden];
              hiddenNew[index] = false;
              setHidden(hiddenNew);
            }}
          >
            <TableCell>
              <span style={{ fontSize: '1.8rem' }}>{index + 1}</span>
            </TableCell>
            <TableCell align="left">
              <Skeleton variant="text" />
            </TableCell>
            <TableCell align="center">
              <Skeleton variant="text" />
            </TableCell>
            <TableCell align="center">
              <Skeleton variant="text" />
            </TableCell>
            <TableCell align="center">
              <Skeleton variant="text" />
            </TableCell>
            <TableCell align="center">
              <Skeleton variant="text" />
            </TableCell>
          </TableRow>
        ) : (
          <TableRow key={guildName}>
            <TableCell>
              <span style={{ fontSize: '1.8rem' }}>{index + 1}</span>
              <Avatar src={guildLogo} style={{ float: 'right' }} />
            </TableCell>
            <TableCell align="left">
              {guildName} [{guildTag}]
            </TableCell>
            <TableCell align="center">
              {professions.split(',').map((profession, index2) => (
                <Tooltip
                  key={`${profession}_${index2.toString()}`}
                  content={
                    <>
                      <DetailsHeader>
                        {players.split(',')[index2]}
                      </DetailsHeader>
                      {videos.split(',')[index2]
                        ? 'Click to play the video'
                        : 'No video :('}
                    </>
                  }
                >
                  <span>
                    <ModalVideo src={videos.split(',')[index2]}>
                      {(handleOpen) => (
                        <IconButton
                          classes={{ root: classes.iconButton }}
                          onClick={
                            videos.split(',')[index2] ? handleOpen : null
                          }
                          size="large"
                        >
                          <div style={{ position: 'relative' }}>
                            <Specialization
                              name={profession.trim()}
                              disableText
                              size="medium2"
                            />
                            {videos.split(',')[index2] && (
                              <YouTube
                                style={{
                                  fontSize: 12,
                                  color: '#FF0000',
                                  position: 'absolute',
                                  bottom: 0,
                                  right: 0,
                                }}
                              />
                            )}
                          </div>
                        </IconButton>
                      )}
                    </ModalVideo>
                  </span>
                </Tooltip>
              ))}
            </TableCell>

            <TableCell align="center">
              {log && (
                <Link to={log}>
                  <LibraryBooksOutlinedIcon />
                </Link>
              )}
            </TableCell>
            <TableCell align="center">
              {dayjs.duration(Number(timer)).format('mm:ss.SSS')}
            </TableCell>
            <TableCell align="center">{points}</TableCell>
          </TableRow>
        ),
    );
};

export default EncounterBody;
